import service from '@/services/modules/tablero/tablero'

export default {
  PAIS_PROCEDENCIA_TURISTA() {
    return new Promise((resolve, reject) => {
      service.getPaisProcedenciaTuristas().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CANTIDAD_TURISTAS_ANIO() {
    return new Promise((resolve, reject) => {
      service.getCantidadTuristasAnio().then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  CANTIDAD_TURISTAS_MES(_, params) {
    return new Promise((resolve, reject) => {
      service.getCantidadTuristasMes(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  TIPOS_VIAJES_TURISTAS(_, params) {
    return new Promise((resolve, reject) => {
      service.getTiposViajesTuristas(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
