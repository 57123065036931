import apiCallPi from '../../othercx'
import config from '../../config'

const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  getFlag(params) {
    const apiService = `/${config.contextPI}/api/v1/plataforma/flag/getValor`
    const query = `?idFlag=${params.idFlag}`
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const apiService = `/${config.contextPI}/api/v1/seguridad/parametro/`
    const params = `${model.idParametro}?valor=${model.valor}`
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService + params)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
