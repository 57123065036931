// import actions
import noticiaAction from './actions/noticia'

const actions = {
  ...noticiaAction,
}

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
