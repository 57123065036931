// import actions
import festividadesAction from './actions/festividades'

// import getters

// import mutations

// import states

const actions = { ...festividadesAction }

const getters = {}

const mutations = {}

const state = {}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
