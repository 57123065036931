import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/diseno/onboarding`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll() {
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    const formData = new FormData()
    formData.append('titulo', model.titulo)
    formData.append('subTitulo', model.subTitulo)
    formData.append('tipo', model.tipo)
    formData.append('orden', model.orden)
    if (model.imagen !== null) formData.append('imagen', model.imagen)
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .post(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const formData = new FormData()
    formData.append('idDisenoOnboarding', model.idDisenoOnboarding)
    formData.append('titulo', model.titulo)
    formData.append('subTitulo', model.subTitulo)
    formData.append('tipo', model.tipo)
    formData.append('orden', model.orden)
    if (model.imagen !== null) formData.append('imagen', model.imagen)
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
