export default [
  {
    path: '/app/seguridad/perfiles',
    name: 'rt-seguridad-perfiles',
    component: () => import('@/views/erp/seguridad/perfiles/PerfilList.vue'),
    meta: {
      resource: 'rt-seguridad-perfiles',
      pageTitle: 'Perfiles',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Perfiles',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/seguridad/usuarios',
    name: 'rt-seguridad-usuarios',
    component: () => import('@/views/erp/seguridad/usuario/UsuarioList.vue'),
    meta: {
      resource: 'rt-seguridad-usuarios',
      pageTitle: 'Usuarios',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Usuarios',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/seguridad/parametro',
    name: 'rt-seguridad-parametros',
    component: () => import('@/views/erp/seguridad/parametro/Parametro.vue'),
    meta: {
      resource: 'rt-seguridad-parametros',
      pageTitle: 'Parámetros',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Parámetros',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/seguridad/log-operaciones',
    name: 'rt-seguridad-log-operacion',
    component: () => import('@/views/erp/seguridad/log_operacion/LogOperacionList.vue'),
    meta: {
      resource: 'rt-seguridad-log-operacion',
      pageTitle: 'Log Operaciones',
      breadcrumb: [
        {
          text: 'Seguridad',
        },
        {
          text: 'Perfiles',
          active: true,
        },
      ],
    },
  },
]
