export default [
  {
    path: '/app/plataforma/transaccion',
    name: 'rt-plataforma-transaccion',
    component: () => import('@/views/erp/plataforma/comercial/transaccion/Transaccion.vue'),
    meta: {
      resource: 'rt-plataforma-transaccion',
      pageTitle: 'Transacciones',
      breadcrumb: [
        {
          text: 'Comercial',
        },
        {
          text: 'Transacciones',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/noticia',
    name: 'rt-plataforma-noticia',
    component: () => import('@/views/erp/plataforma/institucional/noticia/NoticiaList.vue'),
    meta: {
      resource: 'rt-plataforma-noticia',
      pageTitle: 'Noticias',
      breadcrumb: [
        {
          text: 'Institucional',
        },
        {
          text: 'Noticias',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/info/atractivo',
    name: 'rt-plataforma-info-atractivo',
    component: () => import('@/views/erp/plataforma/info/atractivo/Atractivo.vue'),
    meta: {
      resource: 'rt-plataforma-info-atractivo',
      contentRenderer: 'sidebar-left-detached',
      contentClass: 'ecommerce-application',
      pageTitle: 'Atractivos',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Información Turística',
        },
        {
          text: 'Atractivos',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/info/atractivo',
    name: 'rt-plataforma-info-atractivo-editar',
    component: () => import('@/views/erp/plataforma/info/atractivo/detalleficha/FichaAtractivoDetalle.vue'),
    meta: {
      resource: 'rt-plataforma-info-atractivo-editar',
      pageTitle: 'Editar Atractivo',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Información Turística',
        },
        {
          text: 'Atractivos',
        },
        {
          text: 'Editar Atractivo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/apps/plataforma/info/empresa',
    name: 'rt-plataforma-info-empresa',
    component: () => import('@/views/erp/plataforma/info/empresa/Empresa.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/apps/plataforma/info/empresa/:urlSlug',
    name: 'rt-plataforma-info-empresa-tipo-empresa',
    component: () => import('@/views/erp/plataforma/info/empresa/Empresa.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['Agencias', 'draft', 'starred', 'spam', 'trash'].includes(to.params.urlSlug)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/app/plataforma/info/destino',
    name: 'rt-plataforma-info-destino',
    component: () => import('@/views/erp/plataforma/info/destino/DestinoList.vue'),
    meta: {
      resource: 'rt-plataforma-info-destino',
      pageTitle: 'Destino',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Información Turística',
        },
        {
          text: 'Destino',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/info/tipo-empresa',
    name: 'rt-plataforma-info-tipo-empresa',
    component: () => import('@/views/erp/plataforma/info/tipo_empresa/TipoEmpresaList.vue'),
    meta: {
      resource: 'rt-plataforma-info-tipo-empresa',
      pageTitle: 'Tipo de Empresa',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Información Turística',
        },
        {
          text: 'Tipo de Empresa',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/info/festividades',
    name: 'rt-plataforma-info-festividades',
    component: () => import('@/views/erp/plataforma/info/festividad/FestividadList.vue'),
    meta: {
      resource: 'rt-plataforma-info-festividades',
      pageTitle: 'Festividades',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Festividades',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/info/ruta-tematica',
    name: 'rt-plataforma-info-ruta',
    component: () => import('@/views/erp/plataforma/info/ruta-tematica/RutaTematicaList.vue'),
    meta: {
      resource: 'rt-plataforma-info-ruta',
      pageTitle: 'Ruta Temática',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Información Turística',
        },
        {
          text: 'Ruta Temática',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/contenido',
    name: 'rt-plataforma-contenido',
    component: () => import('@/views/erp/plataforma/contenido/ModerarContenido.vue'),
    meta: {
      resource: 'rt-plataforma-contenido',
      pageTitle: 'Moderar Contenido',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Moderar Contenido',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/diseno',
    name: 'rt-plataforma-diseno',
    component: () => import('@/views/erp/plataforma/diseno/Diseno.vue'),
    meta: {
      resource: 'rt-plataforma-diseno',
      pageTitle: 'Diseño',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Diseño',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/acceso/turista',
    name: 'rt-plataforma-access-turista',
    component: () => import('@/views/erp/plataforma/acceso/turista/TuristaList.vue'),
    meta: {
      resource: 'rt-plataforma-access-turista',
      pageTitle: 'Acceso Turista',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Acceso Turista',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/plataforma/acceso/empresa',
    name: 'rt-plataforma-access-empresa',
    component: () => import('@/views/erp/plataforma/acceso/empresa/EmpresaList.vue'),
    meta: {
      resource: 'rt-plataforma-access-empresa',
      pageTitle: 'Acceso Empresa',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Acceso Empresa',
          active: true,
        },
      ],
    },
  },
]
