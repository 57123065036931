import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/pasarela/pedido`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `?idEmpresa=${params.idEmpresa}&idUsuario=${params.idUsuario}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}&tipo=grilla&fecha=${params.fecha}`
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
