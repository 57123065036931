import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/administracion/promocion`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAllPromocion(params) {
    /* &sortBy=${params.sortBy} */
    const query = `?estadoCuenta=${params.estadoCuenta}&idDirectorio=${params.idDirectorio}&limit=${params.limit}&page=${params.page}&query=${params.query}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const query = `/${model.idPromocion}/updateEstado/${model.estado}?reasons=${model.reasons}`
    return new Promise((resolve, reject) => {
      apiCallPi.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}
export default service
