import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/usuario`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `?tipoLogin=${params.tipoLogin}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .delete(`${apiService}/delete/${params.idUsuario}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
