export default [
  {
    path: '/app/administracion/clasificacion',
    name: 'rt-administracion-clasificacion',
    component: () => import('@/views/erp/administracion/clasificacion/ClasificacionList.vue'),
    meta: {
      resource: 'rt-administracion-clasificacion',
      pageTitle: 'Clasificacion',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Clasificacion',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/administracion/acceso-recurso',
    name: 'rt-administracion-accesorecurso',
    component: () => import('@/views/erp/administracion/acceso_recurso/AccesoRecursoList.vue'),
    meta: {
      resource: 'rt-administracion-accesorecurso',
      pageTitle: 'Acceso Recurso',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Acceso Recurso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/administracion/actividad-recurso',
    name: 'rt-administracion-actividadrecurso',
    component: () => import('@/views/erp/administracion/actividad_recurso/ActividadRecursoList.vue'),
    meta: {
      resource: 'rt-administracion-actividadrecurso',
      pageTitle: 'Actividad de Recurso',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Actividad de Recurso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/administracion/infraestructura-recurso',
    name: 'rt-administracion-infrarecurso',
    component: () => import('@/views/erp/administracion/infraestructura_recurso/InfraestructuraRecursoList.vue'),
    meta: {
      resource: 'rt-administracion-infrarecurso',
      pageTitle: 'Infraestructura Recurso',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Infraestructura Recurso',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/administracion/modalidad-turismo',
    name: 'rt-administracion-modalidad-turismo',
    component: () => import('@/views/erp/administracion/modalidad_turismo/ModalidadTurismoList.vue'),
    meta: {
      resource: 'rt-administracion-modalidad-turismo',
      pageTitle: 'Modalidad de Turismo',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Modalidad de Turismo',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/administracion/clase-hospedaje',
    name: 'rt-administracion-clase-hospedaje',
    component: () => import('@/views/erp/administracion/clase_hospedaje/ClaseHospedajeList.vue'),
    meta: {
      resource: 'rt-administracion-clase-hospedaje',
      pageTitle: 'Clase de Hospedaje',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Clase de Hospedaje',
          active: true,
        },
      ],
    },
  },
  {
    path: '/app/administracion/servicio-recurso',
    name: 'rt-administracion-serviciorecurso',
    component: () => import('@/views/erp/administracion/servicio_recurso/ServicioRecursoList.vue'),
    meta: {
      resource: 'rt-administracion-serviciorecurso',
      pageTitle: 'Servicio Recurso',
      breadcrumb: [
        {
          text: 'Administración',
        },
        {
          text: 'Servicio Recurso',
          active: true,
        },
      ],
    },
  },
]
