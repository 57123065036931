import service from '@/services/modules/seguridad/parametro'

export default {
  PARAMETRO_GET_FLAG(_, params) {
    return new Promise((resolve, reject) => {
      service.getFlag(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  PARAMETRO_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
