// import actions
import atractivoAction from './actions/atractivo'
import rutaAccesoAction from './actions/ruta_acceso'
import empresaAction from './actions/empresa'
import rutaTematicaAction from './actions/ruta_tematica'
import contenidoAction from './actions/contenido'
import productoAction from './actions/producto'
import reviewsAction from './actions/reviews'
import disenoOnboardingAction from './actions/diseno_onboarding'
import disenoHeaderAction from './actions/diseno_header'

// import getters

// import mutations

// import states

const actions = {
  ...reviewsAction,
  ...productoAction,
  ...contenidoAction,
  ...atractivoAction,
  ...rutaAccesoAction,
  ...empresaAction,
  ...rutaTematicaAction,
  ...disenoOnboardingAction,
  ...disenoHeaderAction,
}

const getters = {}

const mutations = {}

const state = {}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
