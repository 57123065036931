// import actions
import tableroAction from './actions/tablero'
import minceturAction from './actions/mincetur'

const actions = {
  ...tableroAction,
  ...minceturAction,
}

export default {
  namespaced: true,
  actions,
}
