import apiCall from '../../index'
import config from '../../config'

const apiService = `/${config.contextBO}/api/v1/big_data/report`
const authorization = 'Authorization'
const tipoToken = 'Bearer'
const service = {
  getCantidadTuristasAnio() {
    return new Promise((resolve, reject) => {
      const query = '/cantidad_turistas_anio'
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getCantidadTuristasMes(params) {
    const query = '/cantidad_turistas_mes'
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query, { params })
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getPaisProcedenciaTuristas() {
    const query = '/pais_procedencia_turistas'
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getTiposViajesTuristas() {
    const query = '/tipos_viajes_turistas'
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
