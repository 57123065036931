// import actions
import turistaAction from './actions/turista'

// import getters

// import mutations

// import states

const actions = {
  ...turistaAction,
}

const getters = {}

const mutations = {}

const state = {}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
