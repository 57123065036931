export default [
  {
    path: '/app/tablero',
    name: 'rt-plataforma-info-tablero',
    component: () => import('@/views/erp/dashboard/tablero/Tablero.vue'),
  },
  /*  {
    path: '/app/tablero',
    name: 'rt-plataforma-info-tablero',
    component: () => import('@/views/erp/plataforma/diseno/Diseno.vue'),
    meta: {
      resource: 'rt-plataforma-info-tablero',
      pageTitle: 'Tablero Indicadores',
      breadcrumb: [
        {
          text: 'Plataforma',
        },
        {
          text: 'Tablero de Indicadores',
          active: true,
        },
      ],
    },
  }, */
]
