import apiCallPi from '../../othercx'
import config from '../../config'

const apiService = `/${config.contextPI}/api/v1/diseno/header`
const authorization = 'Authorization'
const tipoToken = 'Bearer'

const service = {
  findAll(params) {
    const query = `?seccion=${params.seccion}&seccionId=${params.seccionId}&tipoHeader=${params.tipoHeader}`
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    const formData = new FormData()
    formData.append('idDestinoTuristico', model.idDestinoTuristico)
    formData.append('titulo', model.titulo)
    formData.append('subTitulo', model.subTitulo)
    formData.append('tipoHeader', model.tipoHeader)
    formData.append('seccion', model.seccion)
    formData.append('seccionId', model.seccionId)
    formData.append('link', model.link)
    formData.append('orden', model.orden)
    if (model.imagen !== null) formData.append('imagen', model.imagen)
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .post(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    const formData = new FormData()
    formData.append('idDiseno', model.idDiseno)
    formData.append('idDestinoTuristico', model.idDestinoTuristico)
    formData.append('titulo', model.titulo)
    formData.append('subTitulo', model.subTitulo)
    formData.append('tipoHeader', model.tipoHeader)
    formData.append('seccion', model.seccion)
    formData.append('seccionId', model.seccionId)
    formData.append('link', model.link)
    formData.append('orden', model.orden)
    if (model.imagen !== null) formData.append('imagen', model.imagen)
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .put(apiService, formData)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCallPi
        .defaults
        .headers
        .common[authorization] = `${tipoToken} ${localStorage.getItem('tokenPI')}`
      apiCallPi
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
