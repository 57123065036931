import apiCall from '../../index'
import config from '../../config'

const apiService = `/${config.contextBO}/api/v1/big_data/report/migraciones`
const authorization = 'Authorization'
const tipoToken = 'Bearer'
const service = {
  getAnualTuristasInter(params) {
    return new Promise((resolve, reject) => {
      const query = `/llegada_anual_turistas_internacionales?tipo=${params.tipo}&anio=${params.anio}`
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  getAnualTuristasInterAniosMes(params) {
    return new Promise((resolve, reject) => {
      const query = `/llegada_mensual_turistas_internacionales?anios=${params.anios}`
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  /* read json of assets countries.json */

}

export default service
