import service from '@/services/modules/tablero/mincetur'

export default {
  ANUAL_TURISTAS_INTERNACIONAL(_, params) {
    return new Promise((resolve, reject) => {
      service.getAnualTuristasInter(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  MES_TURISTAS_INTERNACIONAL(_, params) {
    return new Promise((resolve, reject) => {
      service.getAnualTuristasInterAniosMes(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  /* read json of assets countries.json */

}
