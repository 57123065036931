import service from '@/services/modules/plataforma/empresa'

export default {
  EMPRESA_FIND_ALL(_, params) {
    return new Promise((resolve, reject) => {
      service.findAll(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_CREATE(_, params) {
    return new Promise((resolve, reject) => {
      service.create(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_UPDATE(_, params) {
    return new Promise((resolve, reject) => {
      service.update(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_DELETE(_, params) {
    return new Promise((resolve, reject) => {
      service.delete(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_AGENCIA_FIND_DATA_ADDITIONAL(_, params) {
    return new Promise((resolve, reject) => {
      service.findDataAdditionalAgencia(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_HOSPEDAJE_FIND_DATA_ADDITIONAL(_, params) {
    return new Promise((resolve, reject) => {
      service.findDataAdditionalHospedaje(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_RESTAURANTE_FIND_DATA_ADDITIONAL(_, params) {
    return new Promise((resolve, reject) => {
      service.findDataAdditionalRestaurante(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_AGENCIA_CREATE_UPDATE_DATA_ADDITIONA(_, params) {
    return new Promise((resolve, reject) => {
      service.createDataAdditionalAgencia(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_HOSPEDAJE_CREATE_UPDATE_DATA_ADDITIONA(_, params) {
    return new Promise((resolve, reject) => {
      service.createDataAdditionalHospedaje(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
  EMPRESA_RESTAURANTE_CREATE_UPDATE_DATA_ADDITIONA(_, params) {
    return new Promise((resolve, reject) => {
      service.createDataAdditionalRestaurante(params).then(res => {
        resolve(res)
      }).catch(err => {
        reject(err)
      })
    })
  },
}
