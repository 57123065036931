import apiCall from '../../index'
import config from '../../config'

const apiService = `/${config.contextBO}/api/v1/festividades`
const authorization = 'Authorization'
const tipoToken = 'Bearer'
const apiMultimedia = `/${config.contextBO}/api/v1/multimedia`
const service = {
  findAll(params) {
    const query = `?idDestino=${params.idDestino}&limit=${params.limit}&page=${params.page}&query=${params.query}&sortBy=${params.sortBy}`
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(apiService + query)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  create(model) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  update(model) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .put(apiService, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  delete(params) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .delete(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  findOneFestividad(params) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .get(`${apiService}/${params.id}`)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
  postMultimedia(model) {
    return new Promise((resolve, reject) => {
      apiCall.defaults.headers.common[
        authorization
      ] = `${tipoToken} ${localStorage.getItem('accessToken')}`
      apiCall
        .post(apiMultimedia, model)
        .then(res => resolve(res.data))
        .catch(err => reject(err))
    })
  },
}

export default service
