// import actions
import transaccionAction from './actions/transaccion'

const actions = {
  ...transaccionAction,
}

const getters = { }

const mutations = { }

const state = { }

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
